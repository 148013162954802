@import './src/assets/scss/mixins-and-vars';

.disclaimer {
    font-size: 12px;

    &-empty {
        padding: 10px 30px;
        border: 1px solid $color-line;
        border-radius: 4px;
        color: $color-light;
        font-style: italic;
    }
}
