@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-Light.ttf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-Thin.ttf');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans/IBMPlexSans-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}
