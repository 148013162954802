@import './mixins-and-vars';

$f: '.formik';

#{$f} {
    &-control {
        &._file,
        &._files {
            #{$f} {
                &-control-container {
                    width: auto;
                    min-width: 100%;
                    margin-left: -7px;
                    margin-right: -7px;
                }

                &-control__file {
                    &-descr {
                        order: 2;
                        flex: 1 1;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-left: 7px;
                        margin-right: 7px;
                        font-size: 11px;
                        overflow: hidden;

                        &-item {
                            position: relative;
                            display: inline-flex;
                            flex-direction: column;

                            &:not(:last-child) {
                                margin-bottom: 5px;
                            }
                        }

                        &-item-download {
                            position: absolute;
                            left: 0;
                            top: 0;

                            .icon {
                                @include icon-mask($maskImage: url('../../assets/img/icon-file-earmark-arrow-down-fill.svg'));
                                width: 16px;
                                height: 16px;
                                background-color: $color-accent;

                                &:hover {
                                    background-color: $color-accent-hover;
                                }
                            }
                        }

                        &-item-download + .formik-control__file-descr-item-content {
                            padding-left: 20px;
                        }

                        .name {
                            display: inline-block;
                            vertical-align: bottom;
                            max-width: calc(100% - 40px);
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    &-delete {
                        position: absolute;
                        top: 0;
                        right: -7px;
                        height: auto;
                        padding-left: 7px;
                        padding-right: 7px;
                        transition: 0.2s;
                        opacity: 0.6;

                        &:hover {
                            opacity: 1;
                        }

                        .icon {
                            @include icon-mask($maskImage: url('../../assets/img/icon-clear.svg'), $maskSize: '8px');
                            width: 16px;
                            height: 16px;
                            background-color: $color-danger;
                        }
                    }
                }

                &-label {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: calc(50% - 14px);
                    height: 32px;
                    padding: 5px 10px;
                    margin-left: 7px;
                    margin-right: 7px;
                    margin-bottom: 0;
                    border: 1px solid #ececec;
                    border-radius: 4px;
                    font-weight: normal;
                    cursor: pointer;
                    transition: 0.2s;

                    &:hover {
                        border-color: $color-light;
                    }

                    .icon {
                        @include icon-mask($maskImage: url('../../assets/img/icon-file.svg'), $maskSize: '13px');
                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                        background-color: $color-regular;
                    }
                }
            }
        }

        &._file._image {
            #{$f} {
                &-control__file {
                    &-img-container {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 200px;
                        margin-top: 5px;
                        border: 1px solid $color-line;
                        border-radius: 4px;
                        padding: 20px;
                        overflow: hidden;
                    }

                    &-img {
                        max-width: 100%;
                        max-height: 100%;
                    }

                    &-descr {
                        .name {
                            max-width: calc(100% - 25px);
                        }
                    }

                    &-delete {
                        right: 0;
                    }
                }

                &-label {
                    .icon {
                        @include icon-mask($maskImage: url('../../assets/img/icon-card.svg'));
                    }
                }
            }
        }

        &-container {
            display: flex;
            width: 100%;
        }
    }

    &-input,
    &-textarea {
        order: 2;
    }

    &-label {
        order: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-tip {
            @include icon-mask($maskImage: url('../../assets/img/icon-question-circle.svg'));
            width: 15px;
            height: 15px;
            margin-left: 10px;
            background-color: $color-light;
            transition: 0.2s;

            &:hover {
                background-color: $color-regular;
            }
        }
    }

    &-tooltip {
        max-width: 350px;
    }

    &-input[type='file']:disabled + &-label {
        pointer-events: none;
        user-select: none;
        opacity: 0.6;
    }

    &-select {
        flex: 1;
        margin-left: 7px;
        margin-right: 7px;

        ._show-amount & {
            max-width: calc(100% - 115px);
        }

        &-wrapper-container {
            position: relative;
            display: flex;
            margin-left: -7px;
            margin-right: -7px;
        }

        &-amount-wrapper {
            flex: none;
            width: 85px;
            margin-left: 7px;
            margin-right: 7px;
        }

        &-remove {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 100%;

            &-btn {
                .icon {
                    @include icon-mask($maskImage: url('../../assets/img/icon-clear.svg'), $maskSize: 8px);
                    width: 16px;
                    height: 16px;
                    background-color: $color-danger;
                    opacity: 0.4;
                }

                &:hover .icon {
                    opacity: 1;
                }
            }
        }

        &-add {
            margin-top: 5px;

            &-btn {
                opacity: 0.4;

                .icon {
                    @include icon-mask($maskImage: url('../../assets/img/icon-clear.svg'), $maskSize: 8px);
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                    background-color: $color-regular;
                    transform: rotate(45deg);
                }

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
