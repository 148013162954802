@import 'mixins-and-vars';

body {
    margin: 0;
    font-family: $font-regular;
    font-size: $font-size-default;
    color: $color-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

strong {
    font-weight: 600;
}

* {
    outline: none;
    box-sizing: border-box;
}

button {
    font-family: inherit;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;

    &:not(:last-child) {
        margin-bottom: 1em;
    }
}

ul,
ol {
    padding-left: 1.1em;
    margin-top: 0;
    margin-bottom: 0;

    &:not(:first-child) {
        margin-top: 0.5em;
    }

    &:not(:last-child) {
        margin-bottom: 0.5em;
    }
}

.app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    &__alert {
        margin: 10px;
    }
}

.app > * {
    flex: 1;
}

.error {
    color: $color-danger;
}
