@import './src/assets/scss/mixins-and-vars';

.input,
.textarea,
textarea {
    width: 256px;
    max-width: 100%;
    padding: 6px 15px;
    border: 1px solid $color-line;
    border-radius: 4px;
    font-size: 12px;
    font-family: inherit;
    background-repeat: no-repeat;
    outline: none;

    &::placeholder {
        color: $color-light;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }

    &._disabled {
        background-color: $color-disabled-bg;
        color: $color-disabled;
    }

    &-search {
        $paddingSide: '40px';

        position: relative;
        padding-left: #{$paddingSide};
        padding-right: #{$paddingSide};
        background-position: 12px center;
        background-size: 16px 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='M12.0573 10.8812C14.0937 8.26583 13.9098 4.48194 11.5055 2.07762C8.90197 -0.525874 4.68087 -0.525874 2.07738 2.07762C-0.526118 4.68112 -0.526118 8.90222 2.07738 11.5057C4.48169 13.91 8.26558 14.094 10.881 12.0575C10.8923 12.0703 10.904 12.0828 10.9162 12.095L14.4517 15.6305C14.7772 15.9559 15.3048 15.9559 15.6303 15.6305C15.9557 15.3051 15.9557 14.7774 15.6303 14.452L12.0947 10.9165C12.0825 10.9043 12.07 10.8925 12.0573 10.8812ZM10.327 3.25613C12.2796 5.20875 12.2796 8.37458 10.327 10.3272C8.37433 12.2798 5.20851 12.2798 3.25589 10.3272C1.30327 8.37458 1.30327 5.20875 3.25589 3.25613C5.20851 1.30351 8.37433 1.30351 10.327 3.25613Z' fill='%23CFCFCF'/%3E%3C/svg%3E%0A");

        &::-webkit-search-cancel-button {
            @include icon-mask($maskImage: url('../../assets/img/icon-clear.svg'), $maskSize: 8px);
            -webkit-appearance: none;
            position: absolute;
            top: 50%;
            right: calc(#{$paddingSide} / 2);
            transform: translate(50%, -50%);
            width: 13px;
            height: 13px;
            margin: 0;
            background-color: $color-regular;
            cursor: pointer;
            opacity: 0.8;
            transition: 0.2s;

            &:hover {
                opacity: 1;
            }
        }
    }
}

textarea {
    @include custom-scrollbar();
}

.checkbox {
    &-input {
        display: none;
    }

    &-label {
        position: relative;
        display: flex;
        justify-content: space-between;
        min-width: 14px;
        min-height: 14px;
        padding-left: 14px;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            display: block;
            width: 12px;
            height: 12px;
            border: 1px solid $color-line;
            border-radius: 2px;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__content {
            display: inline-flex;
            max-width: 100%;
            padding-left: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &-input._checked-partially + &-label {
        &::before {
            background-color: $color-accent;
            border-color: $color-accent;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
        }
    }

    &-input:checked + &-label {
        &::before {
            background-color: $color-accent;
            border-color: $color-accent;
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='M6.74186 0.599976L7.59039 1.4485L3.34775 5.69114L3.29602 5.63938L3.2448 5.69114L0.699219 3.14556L1.54775 2.29703L3.29602 4.04578L6.74186 0.599976Z' fill='white'/%3E%3C/svg%3E%0A");
        }
    }

    &-input:disabled + &-label {
        opacity: 0.4;
        cursor: default;
    }
}

.radio {
    &-input {
        display: none;
    }

    &-label {
        position: relative;
        display: block;
        min-width: 12px;
        min-height: 12px;
        padding-left: 12px;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            border-radius: 50%;
        }

        &::before {
            display: block;
            width: 16px;
            height: 16px;
            border: 1px solid $color-line;
        }

        &::after {
            display: none;
            left: 4px;
            width: 10px;
            height: 10px;
            background-color: $color-accent;
        }

        &__content {
            padding-left: 10px;
        }
    }

    &-input:checked + &-label {
        &::after {
            display: block;
        }
    }
}
