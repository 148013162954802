@import './src/assets/scss/mixins-and-vars';

$dp: '.react-datepicker';

#{$dp} {
    position: relative;
    display: inline-flex;
    padding: 15px 23px;
    background-color: $color-bg-default;
    border: 1px solid $color-line;
    border-radius: 4px;
    font-size: 12px;

    &-popper {
        padding-top: 5px;
        z-index: 1;
    }

    &__aria-live {
        display: none;
    }

    &__month-container {
        width: 208px;
    }

    &__week {
        display: flex;
    }

    &__day {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        margin: 1px;
        border-radius: 3px;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            color: $color-accent-hover;
        }

        &-names {
            display: flex;
            border-bottom: 1px solid $color-line;
        }

        &-name {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
        }

        &--outside-month {
            opacity: 0.4;
        }

        &--in-range,
        &--keyboard-selected {
            color: $color-bg-default;
            background-color: $color-accent;

            &:hover {
                color: $color-bg-default;
                background-color: $color-accent-hover;
            }
        }

        &--in-selecting-range {
            color: $color-bg-default;
            background-color: $color-accent;
            opacity: 0.4;

            &:hover {
                color: $color-bg-default;
            }
        }

        &--disabled {
            opacity: 0.4;
            cursor: default;
        }
    }

    &__navigation {
        position: absolute;
        top: 15px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        font-size: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L5 5L1 1' stroke='%23AFAFAF'/%3E%3C/svg%3E%0A");
        border: none;
        border-radius: 3px;
        transition: 0.2s;

        &:hover {
            background-color: $color-line;
        }

        &--previous {
            left: 23px;
            transform: rotate(90deg);
        }

        &--next {
            right: 23px;
            transform: rotate(-90deg);
        }
    }

    &__current-month {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        padding-left: 40px;
        padding-right: 40px;
    }

    &__input-container {
        height: 32px;

        input {
            width: 256px;
            height: 100%;
            padding: 6px 40px 6px 15px;
            border: 1px solid $color-line;
            border-radius: 4px;
            font-size: 12px;
            font-family: inherit;
            color: $color-regular;
            background-repeat: no-repeat;
            background-position: right 7px center;
            background-size: 18px;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7C4.44772 7 4 7.44771 4 8C4 8.55228 4.44772 9 5 9H13C13.5523 9 14 8.55228 14 8C14 7.44771 13.5523 7 13 7H5Z' fill='%23CFCFCF'/%3E%3Cpath clip-rule='evenodd' d='M3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H15C16.6569 18 18 16.6569 18 15V3C18 1.34315 16.6569 0 15 0H3ZM2 15V5H16V15C16 15.5523 15.5523 16 15 16H3C2.44772 16 2 15.5523 2 15Z' fill='%23CFCFCF'/%3E%3C/svg%3E%0A");
            cursor: pointer;

            &::placeholder {
                color: $color-light;
            }
        }
    }

    &__close-icon {
        display: inline-block;
        vertical-align: bottom;
        width: 30px;
        height: 30px;
        border: none;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.36901 0.234884C1.05583 -0.0782947 0.548063 -0.0782947 0.234884 0.234884C-0.0782947 0.548063 -0.0782947 1.05583 0.234884 1.36901L4.8659 6.00002L0.234932 10.631C-0.0782466 10.9442 -0.0782469 11.4519 0.234932 11.7651C0.548111 12.0783 1.05587 12.0783 1.36905 11.7651L6.00003 7.13415L10.631 11.7651C10.9442 12.0783 11.4519 12.0783 11.7651 11.7651C12.0783 11.4519 12.0783 10.9442 11.7651 10.631L7.13415 6.00002L11.7652 1.36901C12.0783 1.05583 12.0783 0.548063 11.7652 0.234884C11.452 -0.0782947 10.9442 -0.0782947 10.631 0.234884L6.00003 4.8659L1.36901 0.234884Z' fill='%237d7d7d'%3E%3C/path%3E%3C/svg%3E");
        background-size: 10px;
        background-color: transparent;
        opacity: 0.6;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }
}
