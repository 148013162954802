@import 'mixins-and-vars';

#app .rc-tooltip {
    background-color: transparent;
    opacity: 1;

    &-inner {
        padding: 10px 15px;
        background-color: $color-bg-default;
        border-radius: 5px;
        border: none;
        box-shadow: 0 5px 10px rgba($color-line, 0.8);
        color: $color-regular;
    }

    &-arrow {
        display: none;
    }
}
