@import './mixins-and-vars';

$br: $btn-border-radius $btn-border-radius $btn-border-radius 0;

.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 5px 10px;
    border-radius: $br;
    background: none;
    box-shadow: none;
    border: 1px solid;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    transition: 0.3s;

    &-name {
        display: flex;
        align-items: center;
        z-index: 1;
    }

    &--primary,
    &--secondary {
        position: relative;

        &:hover {
            color: $color-bg-default;

            .button-name .icon {
                background-color: $color-bg-default;
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border-radius: $br;
            transition: 0.3s;
        }

        &::before {
            opacity: 0;
            background: linear-gradient(180deg, $color-accent -0.02%, #00d344 100.02%), #7daa30;
        }

        &:hover::before {
            opacity: 1;
        }
    }

    &--primary {
        background-color: $color-regular;
        border: none;
        color: $color-bg-default;

        &:hover {
            background-color: transparent;
        }
    }

    &--secondary {
        border-color: $color-line;
        color: $color-regular;
    }

    &--icon,
    &--icon-only {
        padding: 0;
        border-color: transparent;
        color: $color-regular;

        &:hover {
            border-color: transparent;
            color: $color-regular-active;

            .icon {
                fill: $color-accent;
            }
        }
    }

    &--text {
        min-height: auto;
        height: auto;
        padding: 0;
        border: none;
        color: $color-accent;
        font-weight: normal;

        &:hover {
            color: $color-accent-hover;
        }

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 2px;
            background: $color-accent;
            transition: transform 0.5s;
            transform-origin: center left;
            transform: scale(0);
            z-index: 1;
        }

        &:hover,
        &:active,
        &:focus {
            color: $color-accent-hover;

            &::before {
                transform: none;
            }
        }
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
    }

    .icon {
        transition: 0.2s;
    }
}
